<template>
  <div v-cloak>
    <v-row>
      <v-breadcrumbs :items="bread" large></v-breadcrumbs>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="8" sm="10" xs="12">
        <v-text-field v-model="name" label="Buscar" required @keyup="search"></v-text-field>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="4" sm="2" xs="12">
        <v-btn class="mr-4" @click="search"> Buscar </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(s, i) in flista" :key="i" cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <v-card v-if="s.status" elevation="8" :href="s.status ? `/lineas/${s.fideicomiso_id.id}/0/1/${s.id}` : '#'">
          <v-card-title v-if="s.nombre.length < 100" class="notranslate">{{ s.nombre }}</v-card-title>
          <v-card-title v-else>{{ s.nombre.substring(0, 100) + '..' }}</v-card-title>
          <v-card-subtitle>{{ s.descripcion }}</v-card-subtitle>
          <v-card-subtitle
            ><v-chip class="ma-2" color="primary" :disabled="!s.status">
              {{ s.tipo_servicio_id.nombre }}
            </v-chip>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  data: () => ({
    name: '',
    token: localStorage.getItem('token'),
    flista: [],
    fsearch: [],
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Catalogo',
        disabled: true,
        href: '/',
      },
    ],
  }),
  methods: {
    get_operatorias() {
      const header = { token: this.token };
      axios
        .get(`${API_URL}api/servicio/sectorProductivo/${localStorage.getItem('usuario_id')}`, {
          headers: header,
        })
        .then((response) => {
          this.flista = response.data.lineaCreditos;
          this.flista = this.flista.filter((elem) => elem.status === true);
        });
    },
    search() {
      if (this.name === '') {
        this.get_operatorias();
      } else {
        axios
          .post(
            `${API_URL}api/servicio/buscar/`,
            {
              filter: this.name,
            },
            {
              headers: {
                token: localStorage.getItem('token'),
              },
            },
          )
          .then((response) => {
            if (response.data.status === 'error') {
              this.mensaje = response.data.message;
              this.alert = true;
            } else {
              this.alert = false;
              this.flista = response.data.operatorias;
              this.flista = this.flista.filter((elem) => elem.status === true);
            }
          });
      }
    },
  },
  created() {
    this.get_operatorias();
  },
};
</script>
<style>
.card {
  background-color: #f5f5f5;
  color: #444;
  border: 1px solid #ddd;
  border-radius: 4px;

  &.active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
</style>
